@use "../../../../../../styles/variables.scss";

#accounts-container.pending {
  .account-category-container {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }
  .account-container {
    .question-group-container {
      .simple-question {
        grid-template-columns: 1fr auto auto;
        &.denied {
          > div > em {
            margin-left: 10px;
            font-size: 0.8rem;
            color: var(--color-error);
          }
        }
        .icon-button {
          margin-left: auto;
        }
      }
    }
  }
}

@media (max-width: variables.$media-tablet) {
  #accounts-container.to-validate {
    .account-container {
      .question-group-container {
        .title-container {
          grid-template-columns: 1fr auto;
        }
      }
    }
  }
}
