.dashboard {
  #bottom-bar {
    width: 100%;
    background: var(--color-gray0-transparent);
    backdrop-filter: blur(20px);
    height: 80px;
    position: fixed;
    bottom: 0;
    z-index: 100;
    // left: 0;
    // right: 0;
    inset-inline: 0;

    #support-btn {
      display: flex;
      left: 50px;
      bottom: 12px;
      position: fixed;
      background: var(--color-gray0);
    }

    #notification-btn {
      right: 50px;
      bottom: 12px;
      position: fixed;
      display: flex !important;
    }
  }

  aside {
    .navlink {
      padding-left: 10px;
    }
    .navlink-group {
      padding-left: 0;
      padding-right: 0;
      .navlink {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    #add-company-btn {
      padding: 3px 6px;
    }

    #my-companies-btn {
      grid-template-columns: auto 1fr;
      text-align: left;
      margin: 8px auto 3px;
      padding: 6px;
      width: 95%;
      font-weight: 600;
      border-radius: 80px;
      .circle {
        background: var(--color-blue-light);
        svg {
          fill: var(--color-text-soft);
        }
      }
      &:hover {
        background: var(--color-gray1);
        .circle {
          svg {
          }
        }
      }
    }

    .aside-company-step {
      max-height: 100%;
      min-width: 300px;
      background: var(--color-gray0-transparent);
      border-radius: 10px;
      overflow: hidden;

      &#aside-company-search {
        #company-type-search {
          width: 95%;
          margin: 5px auto 0;
          padding: 3px;
          border-radius: 40px;
          background: var(--color-gray1);

          .tooltip-container {
            width: 100%;
          }
          .item {
            width: 100%;
            height: 38px;
            border-radius: 25px;
            background: var(--color-gray0-transparent);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            cursor: pointer;
            &:hover {
              background: var(--color-gray2);
            }
            &.selected {
              background: var(--color-active);
              opacity: 0.8;
              svg {
                color: white;
                fill: white;
              }
            }
          }
        }

        .companies-list {
          .navlink {
            font-size: 0.8rem;
            padding-block: 3px;
          }
        }
      }
      &#aside-company-pes {
        .link-container {
          overflow-y: auto;
          max-height: 100%;
        }
      }
    }

    .aside-company-step {
      border-radius: 0 0 15px 15px;
    }
  }
}
