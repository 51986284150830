#accounts-container.archived {
  .account-category-container {
    margin-top: 30px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  .account-container {
    .question-group-container {
      .simple-question {
        grid-template-columns: 1fr auto;
        .question {
          background-color: var(--color-errorBackground-hover);
          .preview-container {
            .img-container {
              background-color: var(--color-gray2);
              &:hover {
                background-color: var(--color-gray3);
              }
            }
          }
        }
      }
    }
  }
}
