#connector-types {
  width: 100%;
  height: 100%;
  .wrapper {
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        margin-top: 5px;
        font-weight: 600;
        font-size: 1.1rem;
      }
    }
    .tile-choice {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px !important;

      .tooltip-container {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      .default-icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: var(--color-main);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }
  }

  > .tile {
    margin: unset;
  }
}

#exercices {
  .period {
    text-align: left;
    width: 100%;
    max-width: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-block: 2px;
    min-height: 75px;
    .grid {
      width: unset !important;
    }

    &.closed {
      background: var(--color-gray3);
      &,
      * {
        cursor: not-allowed;
      }
    }
  }

  #computed_values {
    strong {
      font-size: 1.5rem;
      font-weight: 800;
      padding-left: 10px;
      padding-right: 10px;
    }
    width: 100%;
    background: var(--color-gray1);
    border: 1px solid var(--color-gray2);
    border-radius: 15px;
    margin: 20px auto 5px;
    padding: 15px 25px 15px;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    #dates {
      text-align: center;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      flex-wrap: wrap;
    }
  }
}
