@use "@/styles/variables.scss";

#accounts-container.processed {
  display: grid;
  background: violet;
  gap: 15px;
  grid-template-columns: 1fr;
  .tile {
    min-width: 100%;
  }
  .account-container {
    background: yellow;
    .question-group-container {
      .additionnal-content {
        margin: 5px;
      }
      .simple-question {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;
        position: relative;

        .question {
          position: relative;
          background: fuchsia;

          .icon-question {
            position: absolute;
            top: 20px;
            left: 30px;
          }

          .preview-container {
            max-width: 150px;
            .img-container {
              background-color: var(--color-gray2);
              &:hover {
                background-color: var(--color-gray3);
              }
            }
          }
        }
        .answer {
          margin: 0;
          border-color: var(--color-gray2);
          *:last-of-type {
            margin-bottom: 0;
          }
          .preview {
            max-width: 180px;
          }
        }
      }
    }
  }
}

@media (max-width: variables.$media-medium-screen) {
  #accounts-container.processed {
    grid-template-columns: auto;
  }
}
